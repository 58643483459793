import { version } from './version';

export const environment = {
  BASE_URL: 'https://dtepaulinia.giss.com.br/PlatformaDTe',
  production: false,
  product: 'dte',
  client: 'eicon',
  i18n: 'pt_br',
  version: version,
  i18nPath: './assets/i18n/',
  assetsPath: './assets/',
  powerbi: '3536505',
  schema: 'pbi_dte_paulinia',
  groupId: '933c9fdd-6c9e-43f8-b933-abfc89537ce8',
  analiseGeral: '2951327a-f08e-4119-ba33-925e58f9854e',
  analiseUsuarios: 'a56eb298-ed28-45e4-8a1a-c0425e0b9249',
  analiseComunicados: '9c175dbb-43c7-46bf-bd82-9bee92870227',
  analiseProcurador: 'aaf00b76-f758-4521-a185-6187e46b6bab',
  analiseEventos: 'c68a10b9-08c0-4a1b-9c78-27e0f0bd01f6'
};
